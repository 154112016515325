/*
For more information about authentication adapters, visit:
- https://manual.os-js.org/v3/tutorial/auth/
- https://manual.os-js.org/v3/guide/auth/
- https://manual.os-js.org/v3/development/
*/
import axios from "axios";
import * as CryptoJS from "crypto-js";

// For Encryption/Decryption
const encryptDecryptValuePassword = "fHnP9iso5WM68B82LlWMtxT";
const encryptDecryptKeyPassword = "sK0Re5q5GRADKJxWd1yhBgp";
const salt = CryptoJS.enc.Hex.parse("4acfedc7dc72a9003a0dd721d7642bde");
const iv = CryptoJS.enc.Hex.parse("69135769514102d0eded589ff874cacd");

const localIpUrl = require("local-ip-url");

const myAdapter = (core, config) => ({
  // ensures a value is not empty, null or undefined
  valueIsDefined(value) {
    if (
      value === "" ||
      value === null ||
      value === "null" ||
      value === undefined ||
      value === "undefined"
    ) {
      return false;
    }
    return true;
  },

  // For Encryption/Decryption
  encryptData(data, password) {
    let key128Bits100Iterations = CryptoJS.PBKDF2(password, salt, {
      keySize: 128 / 32,
      iterations: 100,
    });
    let encryptOutput = CryptoJS.AES.encrypt(data, key128Bits100Iterations, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();

    return encryptOutput;
  },

  decryptData(data, password) {
    let key128Bits100Iterations = CryptoJS.PBKDF2(password, salt, {
      keySize: 128 / 32,
      iterations: 100,
    });
    let decryptOutput = CryptoJS.AES.decrypt(data, key128Bits100Iterations, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);

    return decryptOutput;
  },

  encryptToLocalStorage(key, data) {
    localStorage.setItem(
      this.encryptData(key, encryptDecryptKeyPassword),
      this.encryptData(data, encryptDecryptValuePassword)
    );
  },

  decryptFromLocalStorage(key, json = true) {
    let encryptedKey = this.encryptData(key, encryptDecryptKeyPassword);
    if (this.valueIsDefined(localStorage.getItem(encryptedKey))) {
      if (json == true) {
        let result = "";
        try {
          result = JSON.parse(
            this.decryptData(
              localStorage.getItem(encryptedKey),
              encryptDecryptValuePassword
            )
          );
        } catch (err) {
          console.log(err, "json error");
        } finally {
          return result;
        }
      }
      else
        return this.decryptData(
          localStorage.getItem(encryptedKey),
          encryptDecryptValuePassword
        );
    } else {
      return "";
    }
  },

  removeFromLocalStorage(key) {
    let encryptedKey = this.encryptData(key, encryptDecryptKeyPassword);
    localStorage.removeItem(encryptedKey);
  },

  userHasPermission(permission) {
    const loggedInUserPermissions = this.getLoggedInUserPermissions();
    if (loggedInUserPermissions && loggedInUserPermissions.length > 0) {
      return loggedInUserPermissions.some((userPermission) => {
        return userPermission.MODE.toLowerCase() === permission.toLowerCase();
      });
    } else {
      return false;
    }
  },

  getLoggedInUser() {
    return this.decryptFromLocalStorage("user");
  },

  getApplicationID() {
    const user = this.getLoggedInUser();
    return user ? user.APPID : "";
  },

  getLoggedInUserPermissions() {
    return this.decryptFromLocalStorage("user_permissions");
  },

  getBlacklistedApps() {
    const blacklist = [];
    if (!this.userHasPermission("VIEWGUIAXCTRL")) {
      blacklist.push("AccessControl");
    }
    if (!this.userHasPermission("VIEWGUICONFIG")) {
      blacklist.push("SystemConfig");
    }
    if (!this.userHasPermission("VIEWGUIHCP")) {
      blacklist.push("HCPsAdministration");
    }
    if (!this.userHasPermission("XYZ")) {
      blacklist.push("Mailbox");
    }
    return blacklist;
  },

  async makeRemoteRequest(
    method,
    params,
  ) {
    let url = "https://api.testing.melone.io";
    let httpOptions = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        ApplicationId: this.getApplicationID()
      },
    };

    method = method.toLowerCase();
    if (method === "get" || method === "delete") {
      if (method === "get") {
        url += `?${params}`;
      } else {
        httpOptions.data = params;
      }
      return await axios[method](url, httpOptions)
        .then((response) => {
          let data = response.data;
          if (data.error === false) {
            return data.result.value;
          } else {
            throw new Error(data.error.msg);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
    } else if (method === "post" || method === "put" || method === "patch") {
      return await axios[method](url, params, httpOptions)
        .then((response) => {
          let data = response.data;
          if (data.error === false) {
            return data.result.value;
          } else {
            throw new Error(data.error.msg);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
    } else {
      throw new Error(`Method not implemented: ${method}`);
    }
  },

  async login(values) {
    //if user is already logged in, we resurrect home page
    if (values.resurrect) {
      const user = this.getLoggedInUser();
      return { id: user.USERID, username: user.USERNAME, blacklist: this.getBlacklistedApps() };
    }

    const username = values.username;
    const orgAlias = values.orgAlias;
    const password = values.password;
    const machineID = localIpUrl();
    const machineIDType = "host";
    const magic = values.magic;

    if (!username || !password) {
      throw new Error("Please provide a username and a password.");
    } else {
      let method = "post";
      let params = new FormData();

      // These parameters are always passed
      params.append("_req", "login");

      // any other parameter is only passed if not empty or null
      if (this.valueIsDefined(username)) {
        params.append("user", username);
      }
      if (this.valueIsDefined(orgAlias)) {
        params.append("org", orgAlias);
      }
      if (this.valueIsDefined(password)) {
        params.append("pass", password);
      }
      if (this.valueIsDefined(machineID)) {
        params.append("mid", machineID);
      }
      if (this.valueIsDefined(machineIDType)) {
        params.append("midtype", machineIDType);
      }
      if (this.valueIsDefined(magic)) {
        params.append("magik", magic);
      }

      try {
        const response = await this.makeRemoteRequest(
          method,
          params,
        );

        if (response[0]) {
          this.encryptToLocalStorage("user", JSON.stringify(response[0][0]));
        }
        this.encryptToLocalStorage(
          "user_permissions",
          JSON.stringify(response[1])
        );
        const orgDetails = await this.getOrgDetails();
        if (orgDetails[0]) {
          this.encryptToLocalStorage("orgDetails", JSON.stringify(orgDetails[0]));
        }
        window.location.href = "/";

        return { id: response[0][0].USERID, username: response[0][0].USERNAME, blacklist: this.getBlacklistedApps() };
      } catch (err) {
        console.log("login failed", err);
        throw new Error(err);
      }
    }
  },

  async getOrgDetails() {
    const method = "get";
    let params = "_req=qoinfo";

    try {
      const response = await this.makeRemoteRequest(method, params);
      return response;
    } catch (err) {
      throw err;
    }
  },

  async logout() {
    // And perform special operations on logout
    let method = "post";
    let params = new FormData();

    // These parameters are always passed
    params.append("_req", "logout");

    try {
      await this.makeRemoteRequest(method, params);

      this.removeFromLocalStorage("user");
      this.removeFromLocalStorage("user_permissions");
      this.removeFromLocalStorage("orgDetails");
      return true;
    } catch (err) {
      console.log("logout failed", err);

      let errorMessage = "An error occurred";
      if (err.message) {
        errorMessage = err.message;
      } else if (err.msg) {
        errorMessage = err.msg;
      }
      if (typeof errorMessage === "object") {
        errorMessage = errorMessage.toString();
      }
      errorMessage = errorMessage.replaceAll("AxiosError: ", "");
      errorMessage = errorMessage.replaceAll("Error: ", "");

      if (
        errorMessage === "No authentication token for request" ||
        errorMessage === "Missing or empty authentication token" ||
        errorMessage === "Connection is invalid" ||
        errorMessage === "Not logged in"
      ) {
        errorMessage += ". You are being redirected to the login page...";
      }
      core.make("osjs/notification", {
        message: errorMessage,
        sound: "dialog-warning",
        title: "",
        timeout: 2000,
        className: "error-message",
      });
      
      console.error("logout failed", errorMessage);

      setTimeout(() => {
        this.removeFromLocalStorage("user");
        this.removeFromLocalStorage("user_permissions");
        this.removeFromLocalStorage("orgDetails");
        window.location.href = "";
      }, 2000);

      throw new Error(err);
    }
  },

  async register(values) {
    throw new Error("Registration not available");
  },
});

export default myAdapter;
